// src/services/team.js

const baseUrl = process.env.REACT_APP_CORE_BACKEND_BASE_URL;

export const fetchTeams = async (token) => {
  try {
    const response = await fetch(`${baseUrl}/teams/all`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,  // Include the access token in the Authorization header
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch teams');
    }

    return await response.json();
  } catch (error) {
    console.error('Error during fetching teams:', error);
    throw error;
  }
};


  
  // Create a new team
  export const createTeam = async (teamData, token) => {
    try {
      const response = await fetch(`${baseUrl}/teams`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,  // Include the access token in the Authorization header
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(teamData),
      });
  
      if (!response.ok) {
        throw new Error('Failed to create team');
      }
  
      return await response.json();
    } catch (error) {
      console.error('Error during team creation:', error);
      throw error;
    }
  };

  // Remove a user from a team
export const removeUserFromTeam = async (teamId, userId, token) => {
  try {
    const response = await fetch(`${baseUrl}/teams/${teamId}/users/${userId}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to remove user from team');
    }

    return await response.json();
  } catch (error) {
    console.error('Error removing user from team:', error);
    throw error;
  }
};

// Add a user to a team by ID in the URL
export const addUserToTeam = async (teamId, userId, token) => {
  try {
    const response = await fetch(`${baseUrl}/teams/${teamId}/users/${userId}`, {
      method: 'POST',  // POST request
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to add user to team');
    }

    return await response.json();
  } catch (error) {
    console.error('Error adding user to team:', error);
    throw error;
  }
};


// Update team details
export const updateTeamDetails = async (teamData, token) => {
  try {
    const response = await fetch(`${baseUrl}/teams/${teamData.id}`, {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: teamData.name,
        description: teamData.description,
        company_id: teamData.company_id,  // Include company_id in the request
      }),
    });

    if (!response.ok) {
      throw new Error('Failed to update team');
    }

    return await response.json();
  } catch (error) {
    console.error('Error updating team:', error);
    throw error;
  }
};

export const inviteUserToOrganization = async (email, token) => {
  try {
    const response = await fetch(`${baseUrl}/invitations/`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`, // Include the auth token in the header
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }), // Send the email in the request body
    });

    if (!response.ok) {
      throw new Error('Failed to send invitation');
    }

    return await response.json(); // Return the JSON response if successful
  } catch (error) {
    console.error('Error during invitation:', error);
    throw error; // Rethrow the error to handle it in the UI
  }
};


export const fetchPendingInvites = async (token) => {
  try {
    const response = await fetch(`${baseUrl}/invitations/`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch pending invites');
    }

    return await response.json();  // Return the list of pending invites
  } catch (error) {
    console.error('Error fetching pending invites:', error);
    throw error;
  }
};


// Fetch a specific team by ID
export const fetchTeamById = async (teamId, token) => {
  try {
    const response = await fetch(`${baseUrl}/teams/${teamId}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,  // Include the access token in the Authorization header
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch team details');
    }

    return await response.json();
  } catch (error) {
    console.error('Error fetching team details:', error);
    throw error;
  }
};


export const fetchUserTeam = async (token) => {
  try {
    const response = await fetch(`${baseUrl}/users/me/teams`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch user team');
    }

    return await response.json();
  } catch (error) {
    console.error('Error fetching user team:', error);
    throw error;
  }
};